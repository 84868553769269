import { Router } from 'next/router';
import { useEffect } from 'react';
import { IS_PRODUCTION } from '@/lib/constants/env';
import { analytics as analyticsInstance } from '@/lib/services/analytics/instance';

export function useAnalytics() {
	useEffect(() => {
		function handleRouteChange() {
			if (!IS_PRODUCTION) return;

			analyticsInstance.page();
		}

		// Fire initial page view
		handleRouteChange();

		// Fire page views on routing
		Router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);

	return analyticsInstance;
}
