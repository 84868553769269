export enum Contintent {
	AFRICA = 'Africa',
	AMERICA = 'America',
	ASIA = 'Asia',
	EUROPE = 'Europe',
	OCEANIA = 'Oceania',
}

export type Country = {
	code: string;
	name: string;
	age?: number;
	regions?: Omit<Country, 'regions'>[];
};

export type Countries = {
	[key in Contintent]: Country[];
};

export const DEFAULT_LEGAL_AGE = 18;
export const FULLY_ILLEGAL = -1;
export const MULTIPLE_REGIONS = -2;

export const countries: Countries = {
	[Contintent.AFRICA]: [
		{
			code: 'DZ',
			name: 'Algeria',
			age: 19,
		},
		{
			code: 'AO',
			name: 'Angola',
			age: 18,
		},
		{
			code: 'BJ',
			name: 'Benin',
			age: 18,
		},
		{
			code: 'DZ',
			name: 'Botswana',
			age: 18,
		},
		{
			code: 'BF',
			name: 'Burkina Faso',
			age: 18,
		},
		{
			code: 'BI',
			name: 'Burundi',
			age: 18,
		},
		{
			code: 'CM',
			name: 'Cameroon',
			age: undefined,
		},
		{
			code: 'CV',
			name: 'Cape Verde',
			age: undefined,
		},
		{
			code: 'CF',
			name: 'Central African Republic',
			age: undefined,
		},
		{
			code: 'TD',
			name: 'Chad',
			age: 18,
		},
		{
			code: 'KM',
			name: 'Comoros',
			age: 18,
		},
		{
			code: 'CD',
			name: 'Democratic Republic of the Congo',
			age: 18,
		},
		{
			code: 'DJ',
			name: 'Djibouti',
			age: 16,
		},
		{
			code: 'EG',
			name: 'Egypt',
			age: 18,
		},
		{
			code: 'GQ',
			name: 'Equatorial Guinea',
			age: 18,
		},
		{
			code: 'ER',
			name: 'Eritrea',
			age: 18,
		},
		{
			code: 'ET',
			name: 'Ethiopia',
			age: 18,
		},
		{
			code: 'GA',
			name: 'Gabon',
			age: 18,
		},
		{
			code: 'GM',
			name: 'Gambia',
			age: undefined,
		},
		{
			code: 'GH',
			name: 'Ghana',
			age: 18,
		},
		{
			code: 'GW',
			name: 'Guinea-Bissau',
			age: undefined,
		},
		{
			code: 'KE',
			name: 'Kenya',
			age: 18,
		},
		{
			code: 'LS',
			name: 'Lesotho',
			age: undefined,
		},
		{
			code: 'LR',
			name: 'Liberia',
			age: 18,
		},
		{
			code: 'LY',
			name: 'Libya',
			age: 18,
		},
		{
			code: 'MG',
			name: 'Madagascar',
			age: 18,
		},
		{
			code: 'MW',
			name: 'Malawi',
			age: undefined,
		},
		{
			code: 'ML',
			name: 'Mali',
			age: 18,
		},
		{
			code: 'MU',
			name: 'Mauritius',
			age: 18,
		},
		{
			code: 'MA',
			name: 'Morocco',
			age: undefined,
		},
		{
			code: 'MZ',
			name: 'Mozambique',
			age: 18,
		},
		{
			code: 'NA',
			name: 'Namibia',
			age: 18,
		},
		{
			code: 'NE',
			name: 'Niger',
			age: 18,
		},
		{
			code: 'NG',
			name: 'Nigeria',
			age: 18,
		},
		{
			code: 'CG',
			name: 'Republic of the Congo',
			age: undefined,
		},
		{
			code: 'RW',
			name: 'Rwanda',
			age: 18,
		},
		{
			code: 'ST',
			name: 'Sao Tome and Principe',
			age: 16,
		},
		{
			code: 'SN',
			name: 'Senegal',
			age: 18,
		},
		{
			code: 'SC',
			name: 'Seychelles',
			age: 18,
		},
		{
			code: 'SO',
			name: 'Somalia',
			age: undefined,
		},
		{
			code: 'ZA',
			name: 'South Africa',
			age: 18,
		},
		{
			code: 'SS',
			name: 'South Sudan',
			age: undefined,
		},
		{
			code: 'SZ',
			name: 'Swaziland',
			age: 18,
		},
		{
			code: 'SD',
			name: 'Sudan',
			age: 18,
		},
		{
			code: 'TZ',
			name: 'Tanzania',
			age: 18,
		},
		{
			code: 'TG',
			name: 'Togo',
			age: 18,
		},
		{
			code: 'TN',
			name: 'Tunisia',
			age: undefined,
		},
		{
			code: 'UG',
			name: 'Uganda',
			age: 21,
		},
		{
			code: 'ZM',
			name: 'Zambia',
			age: 16,
		},
		{
			code: 'ZW',
			name: 'Zimbabwe',
			age: 18,
		},
	],
	[Contintent.AMERICA]: [
		{
			code: 'AG',
			name: 'Antigua and Barbuda',
			age: undefined,
		},
		{
			code: 'AR',
			name: 'Argentina',
			age: 18,
		},
		{
			code: 'BS',
			name: 'Bahamas',
			age: 18,
		},
		{
			code: 'BZ',
			name: 'Belize',
			age: undefined,
		},
		{
			code: 'BM',
			name: 'Bermuda',
			age: 18,
		},
		{
			code: 'BO',
			name: 'Bolivia',
			age: 18,
		},
		{
			code: 'BR',
			name: 'Brazil',
			age: 18,
		},
		{
			code: 'VG',
			name: 'British Virgin Islands',
			age: 18,
		},
		{
			code: 'CA',
			name: 'Canada',
			age: MULTIPLE_REGIONS,
			regions: [
				{
					code: 'CA-AB',
					name: 'Alberta',
					age: 18,
				},
				{
					code: 'CA-BC',
					name: 'British Columbia',
					age: 19,
				},
				{
					code: 'CA-MB',
					name: 'Manitoba',
					age: 18,
				},
				{
					code: 'CA-NB',
					name: 'New Brunswick',
					age: 19,
				},
				{
					code: 'CA-NL',
					name: 'Newfoundland and Labrador',
					age: 19,
				},
				{
					code: 'CA-NT',
					name: 'Northwest Territories',
					age: 18,
				},
				{
					code: 'CA-NS',
					name: 'Nova Scotia',
					age: 19,
				},
				{
					code: 'CA-NU',
					name: 'Nunavut',
					age: 19,
				},
				{
					code: 'CA-ON',
					name: 'Ontario',
					age: 19,
				},
				{
					code: 'CA-PE',
					name: 'Prince Edward Island',
					age: 21,
				},
				{
					code: 'CA-QC',
					name: 'Quebec',
					age: 18,
				},
				{
					code: 'CA-SK',
					name: 'Saskatchewan',
					age: 18,
				},
				{
					code: 'CA-YT',
					name: 'Yukon',
					age: 19,
				},
			],
		},
		{
			code: 'CL',
			name: 'Chile',
			age: 18,
		},
		{
			code: 'CO',
			name: 'Colombia',
			age: 18,
		},
		{
			code: 'CR',
			name: 'Costa Rica',
			age: 18,
		},
		{
			code: 'CU',
			name: 'Cuba',
			age: 18,
		},
		{
			code: 'KY',
			name: 'Cayman Islands',
			age: 18,
		},
		{
			code: 'DO',
			name: 'Dominican Republic',
			age: 18,
		},
		{
			code: 'EC',
			name: 'Ecuador',
			age: 18,
		},
		{
			code: 'SV',
			name: 'El Salvador',
			age: 18,
		},
		{
			code: 'FK',
			name: 'Falkland Islands',
			age: undefined,
		},
		{
			code: 'GT',
			name: 'Guatemala',
			age: 18,
		},
		{
			code: 'GY',
			name: 'Guyana',
			age: 18,
		},
		{
			code: 'HT',
			name: 'Haiti',
			age: 16,
		},
		{
			code: 'HN',
			name: 'Honduras',
			age: 21,
		},
		{
			code: 'JM',
			name: 'Jamaica',
			age: 18,
		},
		{
			code: 'MX',
			name: 'Mexico',
			age: 18,
		},
		{
			code: 'NI',
			name: 'Nicaragua',
			age: 18,
		},
		{
			code: 'PA',
			name: 'Panama',
			age: 18,
		},
		{
			code: 'PY',
			name: 'Paraguay',
			age: 18,
		},
		{
			code: 'PE',
			name: 'Peru',
			age: 18,
		},
		{
			code: 'TT',
			name: 'Trinidad and Tobago',
			age: 18,
		},
		{
			code: 'US',
			name: 'United States',
			age: 21,
		},
		{
			code: 'UY',
			name: 'Uruguay',
			age: 18,
		},
		{
			code: 'VE',
			name: 'Venezuela',
			age: 18,
		},
	],
	[Contintent.ASIA]: [
		{
			code: 'AF',
			name: 'Afghanistan',
			age: 18,
		},
		{
			code: 'BT',
			name: 'Bhutan',
			age: 18,
		},
		{
			code: 'BN',
			name: 'Brunei',
			age: 18,
		},
		{
			code: 'BD',
			name: 'Bangladesh',
			age: 16,
		},
		{
			code: 'KH',
			name: 'Cambodia',
			age: 18,
		},
		{
			code: 'CN',
			name: 'China',
			age: 18,
		},
		{
			code: 'HK',
			name: 'Hong Kong',
			age: 18,
		},
		{
			code: 'IN',
			name: 'India',
			age: 21,
		},
		{
			code: 'ID',
			name: 'Indonesia',
			age: 18,
		},
		{
			code: 'IR',
			name: 'Iran',
			age: 18,
		},
		{
			code: 'JQ',
			name: 'Iraq',
			age: 18,
		},
		{
			code: 'IL',
			name: 'Israel',
			age: 18,
		},
		{
			code: 'JO',
			name: 'Jordan',
			age: 18,
		},
		{
			code: 'JP',
			name: 'Japan',
			age: 20,
		},
		{
			code: 'KZ',
			name: 'Kazakhstan',
			age: 21,
		},
		{
			code: 'KW',
			name: 'Kuwait',
			age: 21,
		},
		{
			code: 'KG',
			name: 'Kyrgyzstan',
			age: 18,
		},
		{
			code: 'LB',
			name: 'Lebanon',
			age: 18,
		},
		{
			code: 'MO',
			name: 'Macau',
			age: 18,
		},
		{
			code: 'MY',
			name: 'Malaysia',
			age: 18,
		},
		{
			code: 'MV',
			name: 'Maldives',
			age: 18,
		},
		{
			code: 'MN',
			name: 'Mongolia',
			age: 21,
		},
		{
			code: 'NP',
			name: 'Nepal',
			age: 18,
		},
		{
			code: 'KP',
			name: 'North Korea',
			age: 17,
		},
		{
			code: 'OM',
			name: 'Oman',
			age: 18,
		},
		{
			code: 'PK',
			name: 'Pakistan',
			age: 18,
		},
		{
			code: 'PS',
			name: 'Palestine',
			age: 18,
		},
		{
			code: 'PH',
			name: 'Philippines',
			age: 18,
		},
		{
			code: 'QA',
			name: 'Qatar',
			age: 18,
		},
		{
			code: 'SA',
			name: 'Saudi Arabia',
			age: 18,
		},
		{
			code: 'SG',
			name: 'Singapore',
			age: 21,
		},
		{
			code: 'KR',
			name: 'South Korea',
			age: 19,
		},
		{
			code: 'LK',
			name: 'Sri Lanka',
			age: 21,
		},
		{
			code: 'SY',
			name: 'Syria',
			age: 18,
		},
		{
			code: 'TW',
			name: 'Taiwan',
			age: 20,
		},
		{
			code: 'TJ',
			name: 'Tajikistan',
			age: undefined,
		},
		{
			code: 'TH',
			name: 'Thailand',
			age: 20,
		},
		{
			code: 'TM',
			name: 'Turkmenistan',
			age: FULLY_ILLEGAL,
		},
		{
			code: 'AE',
			name: 'United Arab Emirates',
			age: 18,
		},
		{
			code: 'VN',
			name: 'Vietnam',
			age: 18,
		},
		{
			code: 'YE',
			name: 'Yemen',
			age: undefined,
		},
	],
	[Contintent.EUROPE]: [
		{
			code: 'AL',
			name: 'Albania',
			age: 18,
		},
		{
			code: 'AM',
			name: 'Armenia',
			age: 18,
		},
		{
			code: 'AT',
			name: 'Austria',
			age: 18,
		},
		{
			code: 'AZ',
			name: 'Azerbaijan',
			age: 18,
		},
		{
			code: 'BY',
			name: 'Belarus',
			age: 18,
		},
		{
			code: 'BE',
			name: 'Belgium',
			age: 18,
		},
		{
			code: 'BA',
			name: 'Bosnia and Herzegovina',
			age: 18,
		},
		{
			code: 'BG',
			name: 'Bulgaria',
			age: 18,
		},
		{
			code: 'HR',
			name: 'Croatia',
			age: 18,
		},
		{
			code: 'CY',
			name: 'Cyprus',
			age: 18,
		},
		{
			code: 'CZ',
			name: 'Czech Republic',
			age: 18,
		},
		{
			code: 'DK',
			name: 'Denmark',
			age: 18,
		},
		{
			code: 'EE',
			name: 'Estonia',
			age: 18,
		},
		{
			code: 'FI',
			name: 'Finland',
			age: 18,
		},
		{
			code: 'FR',
			name: 'France',
			age: 18,
		},
		{
			code: 'GE',
			name: 'Georgia',
			age: 18,
		},
		{
			code: 'DE',
			name: 'Germany',
			age: 18,
		},
		{
			code: 'GI',
			name: 'Gibraltar',
			age: 18,
		},
		{
			code: 'GR',
			name: 'Greece',
			age: 18,
		},
		{
			code: 'HU',
			name: 'Hungary',
			age: 18,
		},
		{
			code: 'IS',
			name: 'Iceland',
			age: 18,
		},
		{
			code: 'IE',
			name: 'Ireland',
			age: 18,
		},
		{
			code: 'IT',
			name: 'Italy',
			age: 18,
		},
		{
			code: 'XK',
			name: 'Kosovo',
			age: undefined,
		},
		{
			code: 'LV',
			name: 'Latvia',
			age: 18,
		},
		{
			code: 'LI',
			name: 'Liechtenstein',
			age: 16,
		},
		{
			code: 'LT',
			name: 'Lithuania',
			age: 18,
		},
		{
			code: 'LU',
			name: 'Luxembourg',
			age: 16,
		},
		{
			code: 'MT',
			name: 'Malta',
			age: 18,
		},
		{
			code: 'MD',
			name: 'Moldova',
			age: 18,
		},
		{
			code: 'ME',
			name: 'Montenegro',
			age: 18,
		},
		{
			code: 'NL',
			name: 'Netherlands',
			age: 18,
		},
		{
			code: 'MK',
			name: 'North Macedonia',
			age: 18,
		},
		{
			code: 'NO',
			name: 'Norway',
			age: 18,
		},
		{
			code: 'PL',
			name: 'Poland',
			age: 18,
		},
		{
			code: 'PT',
			name: 'Portugal',
			age: 18,
		},
		{
			code: 'RO',
			name: 'Romania',
			age: 18,
		},
		{
			code: 'RU',
			name: 'Russia',
			age: 18,
		},
		{
			code: 'RS',
			name: 'Serbia',
			age: 18,
		},
		{
			code: 'SK',
			name: 'Slovakia',
			age: 18,
		},
		{
			code: 'SI',
			name: 'Slovenia',
			age: 18,
		},
		{
			code: 'ES',
			name: 'Spain',
			age: 18,
		},
		{
			code: 'SE',
			name: 'Sweden',
			age: 18,
		},
		{
			code: 'CH',
			name: 'Switzerland',
			age: MULTIPLE_REGIONS,
			regions: [
				{
					code: 'CH-AG',
					name: 'Aargau',
					age: 16,
				},
				{
					code: 'CH-AR',
					name: 'Appenzell Ausserrhoden',
					age: 16,
				},
				{
					code: 'CH-AI',
					name: 'Appenzell Innerrhoden',
					age: undefined,
				},
				{
					code: 'CH-BL',
					name: 'Basel-Landschaft',
					age: 18,
				},
				{
					code: 'CH-BS',
					name: 'Basel-Stadt',
					age: 18,
				},
				{
					code: 'CH-BE',
					name: 'Bern',
					age: 18,
				},
				{
					code: 'CH-FR',
					name: 'Fribourg',
					age: 16,
				},
				{
					code: 'CH-GE',
					name: 'Geneva',
					age: 18,
				},
				{
					code: 'CH-GL',
					name: 'Glarus',
					age: 16,
				},
				{
					code: 'CH-GR',
					name: 'Graubünden',
					age: 16,
				},
				{
					code: 'CH-JU',
					name: 'Jura',
					age: 18,
				},
				{
					code: 'CH-LU',
					name: 'Luzern',
					age: 16,
				},
				{
					code: 'CH-NE',
					name: 'Neuchâtel',
					age: 18,
				},
				{
					code: 'CH-NW',
					name: 'Nidwalden',
					age: 18,
				},
				{
					code: 'CH-OW',
					name: 'Obwalden',
					age: undefined,
				},
				{
					code: 'CH-SH',
					name: 'Schaffhausen',
					age: 18,
				},
				{
					code: 'CH-SZ',
					name: 'Schwyz',
					age: undefined,
				},
				{
					code: 'CH-SO',
					name: 'Solothurn',
					age: 16,
				},
				{
					code: 'CH-SH',
					name: 'St. Gallen',
					age: 16,
				},
				{
					code: 'CH-TG',
					name: 'Thurgau',
					age: 16,
				},
				{
					code: 'CH-TI',
					name: 'Ticino',
					age: 18,
				},
				{
					code: 'CH-UR',
					name: 'Uri',
					age: 16,
				},
				{
					code: 'CH-VS',
					name: 'Valais',
					age: 16,
				},
				{
					code: 'CH-VD',
					name: 'Vaud',
					age: 18,
				},
				{
					code: 'CH-ZG',
					name: 'Zug',
					age: 18,
				},
				{
					code: 'CH-ZH',
					name: 'Zürich',
					age: 16,
				},
			],
		},
		{
			code: 'TR',
			name: 'Turkey',
			age: 18,
		},
		{
			code: 'UA',
			name: 'Ukraine',
			age: 18,
		},
		{
			code: 'GB',
			name: 'United Kingdom',
			age: MULTIPLE_REGIONS,
			regions: [
				{
					code: 'GB-ENG',
					name: 'England',
					age: 18,
				},
				{
					code: 'GB-WLS',
					name: 'Wales',
					age: 18,
				},
				{
					code: 'BG-SCT',
					name: 'Scotland',
					age: 18,
				},
				{
					code: 'GB-NIR',
					name: 'Northern Ireland',
					age: 18,
				},
			],
		},
	],
	[Contintent.OCEANIA]: [
		{
			code: 'AU',
			name: 'Australia',
			age: 18,
		},
		{
			code: 'FJ',
			name: 'Fiji',
			age: 18,
		},
		{
			code: 'FM',
			name: 'Federated States of Micronesia',
			age: 18,
		},
		{
			code: 'NZ',
			name: 'New Zealand',
			age: 18,
		},
		{
			code: 'PW',
			name: 'Palau',
			age: 18,
		},
		{
			code: 'PG',
			name: 'Papua New Guinea',
			age: 18,
		},
		{
			code: 'WS',
			name: 'Samoa',
			age: 21,
		},
		{
			code: 'SB',
			name: 'Solomon Islands',
			age: 18,
		},
		{
			code: 'TO',
			name: 'Tonga',
			age: 18,
		},
		{
			code: 'TK',
			name: 'Tokelau',
			age: 16,
		},
		{
			code: 'VU',
			name: 'Vanuatu',
			age: 18,
		},
	],
};
