
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import {
	MantineProvider,
	ColorSchemeProvider,
	Global,
	ColorScheme,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { default as Cookies } from 'cookies';
import useTranslation from 'next-translate/useTranslation';
import NextApp from 'next/app';
import Head from 'next/head';
import { PropsWithChildren, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
	NEXT_COLOR_SCHEME_COOKIE,
	NEXT_THEME_COOKIE,
} from '@/lib/constants/cookie';
import { AgeGateProvider } from '@/lib/contexts/AgeGate';
import {
	Theme,
	ThemeAction,
	ThemeProvider,
	useTheme,
} from '@/lib/contexts/Theme';
import { useAnalytics } from '@/lib/hooks/useAnalytics';
import { useLanguage } from '@/lib/hooks/useLanguage';
import { theme } from '@/lib/theme';

import '@/styles/fonts.scss';

type Props = { theme?: Theme; colorScheme?: ColorScheme };

const __Page_Next_Translate__ = function App(props: AppProps & Props) {
	const {
		Component,
		pageProps,
		theme: initialTheme,
		colorScheme: initialColorScheme,
	} = props;
	const { t } = useTranslation();

	useLanguage();
	useAnalytics();

	const [queryClient] = useState(() => new QueryClient());

	return (
		<>
			<Head>
				<title>{t('common:name')}</title>
				<meta
					name='viewport'
					content='minimum-scale=1, initial-scale=1, width=device-width'
				/>
			</Head>

			<QueryClientProvider {...{ client: queryClient }}>
				<Hydrate state={pageProps.dehydratedState}>
					<ThemeProvider {...{ initialTheme, initialColorScheme }}>
						<ThemeAppWrapper>
							<AgeGateProvider>
								<Component {...pageProps} />
							</AgeGateProvider>
						</ThemeAppWrapper>
					</ThemeProvider>
				</Hydrate>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</>
	);
}
__Page_Next_Translate__.getInitialProps = async (appContext: AppContext) => {
	const initialProps: AppInitialProps & Props = await NextApp.getInitialProps(
		appContext,
	);

	const { ctx } = appContext;

	initialProps.theme = 'system';
	initialProps.colorScheme = 'light';

	if (ctx.req && ctx.res) {
		const cookies = new Cookies(ctx.req, ctx.res);
		const themeCookie = cookies.get(NEXT_THEME_COOKIE);
		const colorSchemeCookie = cookies.get(NEXT_COLOR_SCHEME_COOKIE);

		if (
			themeCookie === 'system' ||
			themeCookie === 'light' ||
			themeCookie === 'dark'
		)
			initialProps.theme = themeCookie;

		if (colorSchemeCookie === 'light' || colorSchemeCookie === 'dark')
			initialProps.colorScheme = colorSchemeCookie;
	}

	return initialProps;
};

function ThemeAppWrapper({
	children,
}: PropsWithChildren<unknown>): JSX.Element {
	const [{ colorScheme }, dispatch] = useTheme();

	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={(value) =>
				dispatch({
					type: ThemeAction.ToggleColorScheme,
					payload: value,
				})
			}>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				withCSSVariables
				theme={{
					colorScheme,
					...theme,
				}}>
				<Global
					styles={() => ({
						'html, body, #__next': {
							height: '100%',
						},
						body: {
							lineHeight: 1.5,
							WebkitFontSmoothing: 'antialiased',
						},
						'img, picture, video, canvas, svg': {
							display: 'block',
							maxWidth: '100%',
						},
						'input, button, textarea, select': {
							font: 'inherit',
						},
						'p, h1, h2, h3, h4, h5, h6': {
							overflowWrap: 'break-word',
						},
						'#__next': {
							isolation: 'isolate',
						},
					})}
				/>
				<NotificationsProvider>
					<ModalsProvider>{children}</ModalsProvider>
				</NotificationsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  