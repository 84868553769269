module.exports = {
	locales: ['nl', 'en'],
	defaultLocale: 'nl',
	pages: {
		'*': [
			'common',
			'language-switcher',
			'theme-switcher',
			'age-gate',
			'images',
		],
		'/404': ['pages/not-found'],
		'/500': ['pages/error'],
		'/illegal': ['pages/illegal'],
		'/coming-soon': ['pages/coming-soon'],
	},
	defaultNS: 'common',
	extensionsRgx: /\.page\.(tsx|ts)$/,
	loadLocaleFrom: (locale, namespace) =>
		require(`@steenbok/locales/${locale}/${namespace}.json`),
	logBuild: false,
};
