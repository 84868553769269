// @ts-expect-error `@analytics/mixpanel` is not typed
import mixpanel from '@analytics/mixpanel';
import { default as Analytics } from 'analytics';
// @ts-expect-error `analytics-plugin-do-not-track` is not typed
import doNotTrack from 'analytics-plugin-do-not-track';
import {
	MIXPANEL_API_HOST,
	MIXPANEL_PROJECT_TOKEN,
	NAME,
	VERSION,
} from '@/lib/constants/env';

export const analytics = Analytics({
	app: NAME,
	version: VERSION,
	plugins: [
		mixpanel({
			token: MIXPANEL_PROJECT_TOKEN,
			options: {
				api_host: MIXPANEL_API_HOST,
			},
		}),
		doNotTrack(),
	],
});
