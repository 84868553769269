export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production';

export const MIXPANEL_PROJECT_TOKEN: string =
	process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN ?? '';
export const MIXPANEL_API_HOST: string =
	process.env.NEXT_PUBLIC_MIXPANEL_API_HOST ?? 'https://api-eu.mixpanel.com';
export const VERSION: string = process.env.NEXT_PUBLIC_VERSION ?? '0.0.0';
export const NAME: string = process.env.NEXT_PUBLIC_NAME ?? 'Steenbok';
export const DEFAULT_COUNTRY: string =
	process.env.NEXT_PUBLIC_DEFAULT_COUNTRY ?? 'NL';
export const DEFAULT_COUNTRY_NAME: string =
	process.env.NEXT_PUBLIC_DEFAULT_COUNTRY_NAME ?? 'The Netherlands';
export const DEFAULT_COUNTRY_AGE: number = process.env
	.NEXT_PUBLIC_DEFAULT_COUNTRY_AGE
	? Number(process.env.NEXT_PUBLIC_DEFAULT_COUNTRY_AGE) ?? 18
	: 18;
