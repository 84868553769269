import { ColorScheme } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';
import { DateTime } from 'klokwerk';
import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { useCookie } from 'react-use';
import { useImmerReducer } from 'use-immer';
import {
	NEXT_COLOR_SCHEME_COOKIE,
	NEXT_THEME_COOKIE,
} from '@/lib/constants/cookie';
import { Actions, ContextTuple } from '@/types/utils';

export type Theme = 'system' | ColorScheme;

type ThemeState = {
	theme: Theme;
	themes: Theme[];
	colorScheme: ColorScheme;
	colorSchemes: ColorScheme[];
};

export enum ThemeAction {
	SetTheme = 'THEME/SET_THEME',
	SetColorScheme = 'THEME/SET_COLOR_SCHEME',
	ToggleColorScheme = 'THEME/TOGGLE_COLOR_SCHEME',
	Reset = 'THEME/RESET',
}

type ThemePayload = {
	[ThemeAction.SetTheme]: Theme;
	[ThemeAction.SetColorScheme]: ColorScheme;
	[ThemeAction.ToggleColorScheme]: ColorScheme | undefined;
	[ThemeAction.Reset]: undefined;
};

type ThemeActions = Actions<ThemePayload>;

type ThemeContextTuple = ContextTuple<ThemeState, ThemeActions>;

const initialState: ThemeState = {
	theme: 'system',
	themes: ['system', 'light', 'dark'],
	colorScheme: 'light',
	colorSchemes: ['light', 'dark'],
};

export const ThemeContext = createContext<ThemeContextTuple>([
	initialState,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	(_action: ThemeActions) => {},
]);

ThemeContext.displayName = 'Theme';

type ThemeProviderProps = {
	expires?: Date;
	initialTheme?: Theme;
	initialColorScheme?: ColorScheme;
};

export function ThemeProvider({
	expires = new DateTime().setFullYear((current) => current.fullYear + 1)
		.native,
	initialTheme = 'system',
	initialColorScheme = 'light',
	children,
}: PropsWithChildren<ThemeProviderProps>): JSX.Element {
	const preferredColorScheme = useColorScheme();
	const [state, dispatch] = useImmerReducer(reducer, {
		...initialState,
		theme: initialTheme,
		colorScheme: initialColorScheme,
	});
	const [themeCookie, updateThemeCookie] = useCookie(NEXT_THEME_COOKIE);
	const [colorSchemeCookie, updateColorSchemeCookie] = useCookie(
		NEXT_COLOR_SCHEME_COOKIE,
	);

	const value = useMemo<ThemeContextTuple>(
		(): ThemeContextTuple => [state, dispatch],
		[state, dispatch],
	);

	useEffect(
		function handlePreferredColorSchemeChange() {
			if (state.theme !== 'system') return;

			dispatch({
				type: ThemeAction.SetColorScheme,
				payload: preferredColorScheme,
			});
		},
		[state.theme, preferredColorScheme],
	);

	useEffect(
		function syncThemeCookie() {
			if (themeCookie === state.theme) return;

			updateThemeCookie(state.theme, { expires });
		},
		[themeCookie, state.theme, expires],
	);

	useEffect(
		function syncColorSchemeCookie() {
			if (colorSchemeCookie === state.colorScheme) return;

			updateColorSchemeCookie(state.colorScheme, { expires });
		},
		[colorSchemeCookie, state.colorScheme, expires],
	);

	return (
		<ThemeContext.Provider {...{ value }}>{children}</ThemeContext.Provider>
	);
}

function reducer(draft: ThemeState, action: ThemeActions) {
	switch (action.type) {
		case ThemeAction.SetTheme: {
			draft.theme = action.payload;

			const preferredColorScheme =
				window.matchMedia &&
				window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light';

			if (action.payload === 'system') draft.colorScheme = preferredColorScheme;
			else draft.colorScheme = action.payload;
			break;
		}
		case ThemeAction.SetColorScheme: {
			draft.colorScheme = action.payload;
			break;
		}
		case ThemeAction.ToggleColorScheme: {
			const toggledColorScheme =
				draft.colorScheme === 'dark' ? 'light' : 'dark';
			draft.colorScheme = action.payload ?? toggledColorScheme;
			draft.theme = action.payload ?? toggledColorScheme;
			break;
		}
		case ThemeAction.Reset: {
			draft = initialState;
			break;
		}
		default: {
			throw new Error(`Unhandled action: ${action}`);
		}
	}
}

export function useTheme(): ThemeContextTuple {
	const context = useContext(ThemeContext);

	if (context === undefined)
		throw new Error('useTheme must be used within a ThemeProvider');

	return context;
}
