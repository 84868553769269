import { WritableDraft } from 'immer/dist/internal';
import { DateTime } from 'klokwerk';
import { useRouter } from 'next/router';
import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { useCookie } from 'react-use';
import { useImmerReducer } from 'use-immer';
import { AgeGatePopup } from '@/components/AgeGatePopup';
import { Actions, ContextTuple } from '@/types/utils';
import { FULLY_ILLEGAL } from '../age-gate/data';
import { NEXT_AGE_GATE_COOKIE } from '../constants/cookie';
import { ILLEGAL_AGE_PATH } from '../constants/path';

type AgeGateState = {
	isLoaded: boolean;
	country?: string;
	region?: string;
	age?: number;
	accepted: boolean;
	acceptedAt?: DateTime;
};

export enum AgeGateAction {
	SetCookieResponse = 'AGE_GATE/SET_COOKIE_RESPONSE',
	SetAccepted = 'AGE_GATE/SET_ACCEPTED',
	SetCountry = 'AGE_GATE/SET_COUNTRY',
	SetRegion = 'AGE_GATE/SET_REGION',
	SetAge = 'AGE_GATE/SET_AGE',
	Reset = 'AGE_GATE/RESET',
}

type AgeGatePayload = {
	[AgeGateAction.SetCookieResponse]: AgeGateCookie;
	[AgeGateAction.SetAccepted]: boolean;
	[AgeGateAction.SetCountry]: string | undefined;
	[AgeGateAction.SetRegion]: string | undefined;
	[AgeGateAction.SetAge]: number | undefined;
	[AgeGateAction.Reset]: undefined;
};

type AgeGateActions = Actions<AgeGatePayload>;

type AgeGateContextTuple = ContextTuple<AgeGateState, AgeGateActions>;

const initialState: AgeGateState = {
	isLoaded: false,
	country: undefined,
	region: undefined,
	age: undefined,
	accepted: true,
	acceptedAt: undefined,
};

export const AgeGateContext = createContext<AgeGateContextTuple>([
	initialState,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	(__action: AgeGateActions) => {},
]);

AgeGateContext.displayName = 'AgeGate';

export function AgeGateProvider({
	children,
}: PropsWithChildren<unknown>): JSX.Element {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const [ageGateCookie] = useCookie(NEXT_AGE_GATE_COOKIE);
	const { pathname, replace } = useRouter();

	const value = useMemo<AgeGateContextTuple>(
		(): AgeGateContextTuple => [state, dispatch],
		[state, dispatch],
	);

	useEffect(() => {
		if (!ageGateCookie) return;

		dispatch({
			type: AgeGateAction.SetCookieResponse,
			payload: JSON.parse(ageGateCookie) as AgeGateCookie,
		});
	}, [dispatch, ageGateCookie]);

	useEffect(() => {
		if (pathname === ILLEGAL_AGE_PATH) return;
		if (state.age !== FULLY_ILLEGAL) return;

		replace('/illegal');
	}, [state.age, pathname, replace]);

	return (
		<AgeGateContext.Provider {...{ value }}>
			{state.accepted ? children : <AgeGatePopup>{children}</AgeGatePopup>}
		</AgeGateContext.Provider>
	);
}

function reducer(draft: WritableDraft<AgeGateState>, action: AgeGateActions) {
	switch (action.type) {
		case AgeGateAction.SetCookieResponse: {
			draft.isLoaded = true;
			draft.age = action.payload.age;
			draft.country = action.payload.country;
			draft.region = action.payload.region;
			draft.accepted = action.payload.accepted;
			if (action.payload.acceptedAt)
				draft.acceptedAt = new DateTime(action.payload.acceptedAt);
			break;
		}
		case AgeGateAction.SetAccepted: {
			draft.accepted = action.payload;
			draft.acceptedAt = new DateTime();
			break;
		}
		case AgeGateAction.SetAge: {
			draft.age = action.payload;
			break;
		}
		case AgeGateAction.SetCountry: {
			draft.country = action.payload;
			break;
		}
		case AgeGateAction.SetRegion: {
			draft.region = action.payload;
			break;
		}
		case AgeGateAction.Reset: {
			draft = initialState;
			break;
		}
		default: {
			throw new Error(`Unhandled action: ${action}`);
		}
	}
}

export function useAgeGate(): AgeGateContextTuple {
	const context = useContext(AgeGateContext);

	if (context === undefined) {
		throw new Error('useAgeGate must be used within a AgeGateProvider');
	}

	return context;
}
