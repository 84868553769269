import { default as dayjs } from 'dayjs';
import { DateTime } from 'klokwerk';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCookie } from 'react-use';
import { NEXT_LOCALE_COOKIE } from '@/lib/constants/cookie';

import 'dayjs/locale/nl';
import 'dayjs/locale/en';

type UseLanguageParams = {
	expires?: Date;
};

type UseLanguage = {
	locale: string;
	locales: string[];
	changeLocale: (locale: string) => Promise<void>;
};

export function useLanguage(
	props: UseLanguageParams = {
		expires: new DateTime().setFullYear((current) => current.fullYear + 1)
			.native,
	},
): UseLanguage {
	const {
		locale: routerLocale,
		locales: routerLocales,
		replace,
		asPath,
	} = useRouter();
	const locale = routerLocale ?? 'nl';
	const locales = routerLocales ?? ['nl', 'en'];
	const { lang: translateLocale } = useTranslation();
	const [, updateCookie] = useCookie(NEXT_LOCALE_COOKIE);

	useEffect(() => {
		if (dayjs.locale() === locale) return;

		dayjs.locale(locale);
	}, [locale]);

	useEffect(() => {
		if (translateLocale === locale) return;

		setLanguage(locale);
	}, [locale, translateLocale]);

	async function changeLocale(newLocale: string) {
		updateCookie(newLocale, {
			expires: props.expires,
		});

		await replace(asPath, asPath, {
			locale: newLocale,
			scroll: false,
			shallow: true,
		});
	}

	return { locale, locales, changeLocale };
}
