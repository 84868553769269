import {
	Box,
	Modal,
	Title,
	Text,
	Button,
	LoadingOverlay,
	Checkbox,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { FULLY_ILLEGAL } from '@/lib/age-gate/data';
import { getCountry } from '@/lib/age-gate/utils';
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_NAME } from '@/lib/constants/env';
import { ILLEGAL_AGE_PATH } from '@/lib/constants/path';
import { AgeGateAction, useAgeGate } from '@/lib/contexts/AgeGate';
import { noop } from '@/utils/noop';

export function AgeGatePopup({ children }: PropsWithChildren<unknown>) {
	const { pathname } = useRouter();
	const { t } = useTranslation();
	const [{ age, country: countryCode, accepted }, dispatch] = useAgeGate();
	const { isLoading, mutate: acceptAgeGate } = useMutation(
		() => fetch('/api/accept-age-gate', { method: 'POST' }),
		{
			onError: () => {
				dispatch({
					type: AgeGateAction.SetAccepted,
					payload: false,
				});
			},
			onSuccess: () => {
				dispatch({
					type: AgeGateAction.SetAccepted,
					payload: true,
				});
			},
		},
	);
	const country = getCountry(countryCode ?? DEFAULT_COUNTRY);
	const form = useForm({
		initialValues: {
			accepted: false,
		},
		schema: yupResolver(
			Yup.object().shape({
				accepted: Yup.boolean().required(),
			}),
		),
	});

	if (
		pathname === '/404' ||
		pathname === '/500' ||
		(pathname === ILLEGAL_AGE_PATH && age === FULLY_ILLEGAL)
	)
		return <>{children}</>;

	function accept() {
		acceptAgeGate();
		dispatch({
			type: AgeGateAction.SetAccepted,
			payload: true,
		});
	}

	return (
		<>
			<Modal
				{...{
					id: 'age-gate',
					radius: 'md',
					centered: true,
					closeOnClickOutside: false,
					closeOnEscape: false,
					trapFocus: true,
					opened: !accepted,
					withCloseButton: false,
					onClose: noop,
					size: 600,
					styles: {
						inner: { backdropFilter: 'blur(6px)' },
					},
				}}>
				<Box
					{...{
						component: 'form',
						onSubmit: form.onSubmit(accept),
						sx: {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 20,
						},
					}}>
					<LoadingOverlay {...{ visible: isLoading }} />

					<Title
						{...{
							order: 2,
							align: 'center',
							sx: {
								fontSize: 'var(--mantine-h1-font-size)',
								lineHeight: 'var(--mantine-h1-line-height)',
							},
						}}>
						{t('age-gate:popup.title')}
					</Title>
					<Text {...{ align: 'center' }}>
						{t('age-gate:popup.text', { age })}
					</Text>
					<Checkbox
						{...{
							dataAutofocus: true,
							required: true,
							label: t('age-gate:popup.check', { age }),
							styles: {
								root: { cursor: 'pointer' },
								inner: { cursor: 'pointer' },
								label: { cursor: 'pointer' },
								input: { cursor: 'pointer' },
								icon: { cursor: 'pointer' },
							},
							...form.getInputProps('accepted', { type: 'checkbox' }),
						}}
					/>

					<Button {...{ type: 'submit' }}>{t('age-gate:popup.confirm')}</Button>

					<Text
						{...{
							color: 'dimmed',
							size: 'sm',
							align: 'center',
							sx: { maxWidth: 450 },
						}}>
						<Trans
							{...{
								ns: 'age-gate',
								i18nKey: 'popup.disclaimer',
								components: [<strong key={0} />],
								values: {
									country: t(
										`age-gate:countries.${
											country?.name ?? DEFAULT_COUNTRY_NAME
										}`,
										undefined,
										{
											fallback: country?.name ?? DEFAULT_COUNTRY_NAME,
										},
									),
								},
							}}
						/>
					</Text>
				</Box>
			</Modal>

			{children}
		</>
	);
}
