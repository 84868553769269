import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
	fontFamily: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
	headings: {
		fontFamily: `"Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
	},
	colors: {
		brand: [
			'#EFEBE9',
			'#D7CCC8',
			'#BCAAA4',
			'#A1887F',
			'#8D6E63',
			'#795548',
			'#6D4C41',
			'#5D4037',
			'#4E342E',
			'#3E2723',
		],
	},
	primaryColor: 'brand',
};
