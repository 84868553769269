import {
	countries,
	Countries,
	Country,
	FULLY_ILLEGAL,
	MULTIPLE_REGIONS,
} from './data';

export const flattenedCountries = flattenCountries(countries);

export function flattenCountries(countries: Countries): Country[] {
	return Object.values(countries).reduce((accumulator, value) =>
		accumulator.concat(value),
	);
}

export function getCountry(countryCode: Country['code']): Country | undefined {
	return flattenedCountries.find(
		(country) => country.code.toUpperCase() === countryCode.toUpperCase(),
	);
}

export function getRegion(
	country: Country,
	regionCode: string,
): Omit<Country, 'regions'> | undefined {
	return country.regions?.find(
		(region) => region.code.toUpperCase() === regionCode.toUpperCase(),
	);
}

export function getRegionFromCountry(
	countryCode: Country['code'],
	regionCode?: string,
): Omit<Country, 'regions'> | undefined {
	const country = getCountry(countryCode);

	if (!country) return;
	if (!regionCode) return country;

	return getRegion(country, regionCode);
}

export function isIllegalAge(age: number): boolean {
	return age === FULLY_ILLEGAL;
}

export function hasMultipleRegions(age: number): boolean {
	return age === MULTIPLE_REGIONS;
}
